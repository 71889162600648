import { v4 as uuidv4 } from 'uuid';

export const formatCurrency = (
  amount: number | string | undefined | null,
  currency: string
): string => {
  if (!amount) return 'N/A';
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const getUniqueKey = () => {
  return uuidv4();
};
